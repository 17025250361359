<template>
  <Page class="px-6 w-full" :title="translate(`${LOCALE_PREFIX}.pageTitle`)">
    <div class="flex align-items-center justify-content-center mt-5">
      <h1 class="font-semibold">{{ loginHeader }}</h1>
    </div>

    <template v-if="!isLoginWithEmail">
      <div class="flex align-items-center justify-content-center mt-5" v-if="ssoRedirectError">
        <Message class="w-full" :severity="MessageSeverity.ERROR" :closable="false">
          {{ translate(`${LOCALE_PREFIX}.info.ssoError`) }}

          <template #messageicon>
            <i class="material-symbols-rounded mr-2"> report_problem</i>
          </template>
        </Message>
      </div>

      <div class="flex align-items-center justify-content-center mt-5 w-full">
        <Button
          id="centricOS-login-button"
          class="w-full"
          :label="translate(`${LOCALE_PREFIX}.buttons.ssoLogin`)"
          @click="goToSSO"
        />
      </div>

      <div class="flex align-items-center justify-content-center mt-3 w-full">
        <Divider align="center" type="solid">
          <span class="uppercase">{{ translate(`${LOCALE_PREFIX}.orHelperText`) }}</span>
        </Divider>
      </div>
    </template>

    <template v-else>
      <div class="flex flex-column align-items-center justify-content-center mt-3 text-center">
        <div class="flex align-items-center justify-content-center">
          <Message class="w-full" :severity="MessageSeverity.WARN" :closable="false">
            {{ translate(`${LOCALE_PREFIX}.info.warnInfo`) }}

            <template #messageicon>
              <i class="material-symbols-rounded mr-2"> report_problem</i>
            </template>
          </Message>
        </div>

        <div class="flex align-items-center justify-content-center">
          <span>{{ translate(`${LOCALE_PREFIX}.infoPassword.subtitle`) }}</span>
        </div>
      </div>

      <LoginForm />

      <div class="flex flex-column align-items-center justify-content-center mt-3 text-center">
        <div class="flex align-items-center justify-content-center">
          <span>{{ translate(`${LOCALE_PREFIX}.infoPassword.line1`) }}</span>
        </div>

        <div class="flex align-items-center justify-content-center">
          <span>{{ translate(`${LOCALE_PREFIX}.infoPassword.line2`) }}</span>
        </div>
      </div>
    </template>

    <div class="flex align-items-center justify-content-center mt-3">
      <Button
        id="centricOS-login-button"
        :class="`${ButtonStateClass.LINK}`"
        :label="loginTypeLabel"
        @click="hideShowEmailLogin"
      >
        <template #icon v-if="loginTypeIcon">
          <i class="material-symbols-rounded mr-2"> {{ loginTypeIcon }} </i>
        </template>
      </Button>
    </div>

    <div
      class="flex flex-column align-items-center justify-content-center mt-3 text-center"
      v-if="!isLoginWithEmail"
    >
      <div class="flex align-items-center justify-content-center">
        <span>{{ translate(`${LOCALE_PREFIX}.info.line1`) }}</span>
      </div>

      <div class="flex align-items-center justify-content-center">
        <span>{{ translate(`${LOCALE_PREFIX}.info.line2`) }}</span>
      </div>
    </div>
  </Page>
</template>

<script setup lang="ts">
import { onMounted, computed, ref } from 'vue';
import { useAuthStore, useMainNavigationStore } from '@centric-os/stores';
import { LoginLocalePrefix } from '../../../enums';
import { useTranslation } from '@centric-os/helpers';
import { ButtonStateClass, MessageSeverity } from '@centric-os/components';
import { storeToRefs } from 'pinia';
import LoginForm from '../login-form/LoginForm.vue';

let sso_url = '';

const { translate } = useTranslation();
const LOCALE_PREFIX = LoginLocalePrefix.LOGIN;
const authStore = useAuthStore();
const { intendedRoute, ssoRedirectError } = storeToRefs(authStore);

const isLoginWithEmail = ref<boolean>(false);
const loginHeader = computed<string>(() => {
  return isLoginWithEmail.value
    ? translate(`${LOCALE_PREFIX}.emailLoginTitle`)
    : translate(`${LOCALE_PREFIX}.title`);
});

const loginTypeLabel = computed<string>(() => {
  return isLoginWithEmail.value
    ? translate(`${LOCALE_PREFIX}.buttons.userLoginBack`)
    : translate(`${LOCALE_PREFIX}.buttons.userLogin`);
});

const loginTypeIcon = computed<string>(() => {
  return isLoginWithEmail.value ? 'arrow_back' : '';
});

const hideShowEmailLogin = (): void => {
  isLoginWithEmail.value = !isLoginWithEmail.value;
  authStore.isPasswordReset = false;
};

const isLocalhost = (url: string): boolean => {
  return url.includes('localhost') || url.includes('127.0.0.1');
};

onMounted((): void => {
  if (authStore.isPasswordReset) {
    isLoginWithEmail.value = !isLoginWithEmail.value;
  }
  const navStore = useMainNavigationStore();
  const redirectRouteName = navStore.getDefaultNavigationRoutePath();

  const finalIntendedRoute: string = intendedRoute.value?.path || `/${redirectRouteName}`;

  const oms_url = import.meta.env.VITE_SSO_AUTH_URL;
  const return_url = window.location.origin + finalIntendedRoute;
  const client_id = isLocalhost(return_url)
    ? import.meta.env.VITE_CLIENT_ID_LOCAL
    : import.meta.env.VITE_CLIENT_ID;

  sso_url = `${oms_url}/authorize?client_id=${client_id}&response_type=code&redirect_uri=${return_url}&response_type=code`;
});

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const goToSSO = (): void => {
  window.location.href = sso_url;
};
</script>

