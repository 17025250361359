<template>
  <div
    class="flex flex-column align-items-center justify-content-center mt-3 text-center skip-password-reset"
  >
    <span>{{ translate(`${LOCALE_PREFIX}.info.skipPasswordResetNextTime`) }}</span>
  </div>

  <div class="flex align-items-center justify-content-center mt-3">
    <Button
      id="centricOS-login-button"
      :class="`${ButtonStateClass.LINK} w-full`"
      :label="translate(`${LOCALE_PREFIX}.buttons.userLogin`)"
      @click="goToSSO"
    >
    </Button>
  </div>
</template>

<script setup lang="ts">
import { useAuthStore, useMainNavigationStore } from '@centric-os/stores';
import { storeToRefs } from 'pinia';
import { LoginLocalePrefix } from '../../../enums';
import { ButtonStateClass } from '@centric-os/components';
import { useTranslation } from '@centric-os/helpers';

const authStore = useAuthStore();
const { translate } = useTranslation();
const { intendedRoute } = storeToRefs(authStore);
const LOCALE_PREFIX = LoginLocalePrefix.PASSWORD_RESET;

const isLocalhost = (url: string): boolean => {
  return url.includes('localhost') || url.includes('127.0.0.1');
};

const goToSSO: () => void = () => {
  const navStore = useMainNavigationStore();
  const redirectRouteName = navStore.getDefaultNavigationRoutePath();

  const finalIntendedRoute: string = intendedRoute.value?.path || `/${redirectRouteName}`;

  const oms_url = import.meta.env.VITE_SSO_AUTH_URL;
  const return_url = window.location.origin + finalIntendedRoute;
  const client_id = isLocalhost(return_url)
    ? import.meta.env.VITE_CLIENT_ID_LOCAL
    : import.meta.env.VITE_CLIENT_ID;

  window.location.href = `${oms_url}/authorize?client_id=${client_id}&response_type=code&redirect_uri=${return_url}&response_type=code`;
};
</script>

<style lang="scss" scoped>
.skip-password-reset {
  color: #000000;
}
</style>
