<template>
  <div class="bg-white flex flex-wrap w-full">
    <div ref="fullCalendarHeaderRef" class="sticky top-0 z-3 bg-white w-full">
      <div v-if="!calendarOptions.headerToolbar" class="flex p-3">
        <div v-if="$slots.action" class="flex align-items-center">
          <slot name="action" />
        </div>
        <div class="flex">
          <IconButton @click="goToPrevPage">
            <template #icon>
              <i class="material-symbols-rounded">chevron_left</i>
            </template>
          </IconButton>
          <IconButton @click="goToNextPage">
            <template #icon>
              <i class="material-symbols-rounded">chevron_right</i>
            </template>
          </IconButton>
        </div>
        <div class="flex align-items-center">
          <div
            v-if="!monthDropdownActive"
            @click="monthDropDownVisibilityHandler = true"
            class="flex align-items-center"
          >
            <h2 class="font-semi-bold">{{ currentCalendarMonth }}</h2>
            <i class="material-symbols-rounded ml-2">expand_more</i>
          </div>
          <div v-else class="flex align-items-center">
            <Dropdown
              ref="selectedMonthDropdownRef"
              v-model="currentCalendarMonth"
              :options="months"
              @change="handleMonthChange"
            />
            <IconButton class="ml-2" @click="monthDropDownVisibilityHandler = false">
              <template #icon>
                <i class="material-symbols-rounded">close</i>
              </template>
            </IconButton>
          </div>
        </div>
        <div class="flex align-items-center ml-auto">
          <Button
            :label="t(`calendar.today`)"
            :class="`${ButtonStateClass.OUTLINE} ${ButtonSeverityClass.SECONDARY}`"
            @click="goToTodaysPage"
          />
        </div>
        <div class="px-2 flex align-items-center w-10rem">
          <Dropdown
            v-model="currentCalendarView"
            :options="calendarViews"
            @change="toggleCalendarView"
          />
        </div>
      </div>
    </div>
    <div v-if="$slots.actionColumn" class="action-column sticky top-1">
      <slot name="actionColumn" />
    </div>

    <div v-if="!loading" class="flex-1 z-2 pb-5">
      <FullCalendar ref="fullCalendarRef" :options="calendarOptions" />
    </div>
    <template v-else>
      <div class="flex-1 p-4">
        <Skeleton height="40px" />
        <div class="flex mt-1" style="gap: 0.25rem">
          <template v-for="column in 8" :key="column">
            <Skeleton v-if="column === 1" width="30%" height="40px" />
            <Skeleton v-else height="40px" />
          </template>
        </div>
        <template v-for="row in 5" :key="row">
          <div class="flex my-1" style="gap: 0.25rem">
            <template v-for="column in 8" :key="column">
              <Skeleton v-if="column === 1" width="30%" height="60px" />
              <Skeleton v-else height="60px" />
            </template>
          </div>
        </template>
      </div>
    </template>
  </div>
  <slot v-if="$slots.dialog" name="dialog" />
</template>

<script setup lang="ts">
import FullCalendar from '@fullcalendar/vue3';
import timeGridPlugin from '@fullcalendar/timegrid';
import dayGridPlugin from '@fullcalendar/daygrid';
import rrulePlugin from '@fullcalendar/rrule';
import interactionPlugin from '@fullcalendar/interaction';
import { ref, onMounted, type PropType, watch, computed, nextTick } from 'vue';
import { ButtonSeverityClass, ButtonStateClass } from '../../buttons';
import type { CalendarOptions, EventSourceInput } from '@fullcalendar/core';
import { useFullCalendarActions } from './composables';
import { useElementSize } from '@vueuse/core';
import { useI18n } from 'vue-i18n';
import { useContentPageStore } from '@centric-os/stores';
import { storeToRefs } from 'pinia';

const { t } = useI18n();

const props = defineProps({
  customCalendarOptions: {
    type: Object as PropType<Partial<CalendarOptions>>,
    required: false,
  },
  events: {
    type: Object as PropType<EventSourceInput>,
  },
  loading: {
    type: Boolean,
    required: false,
    default: false,
  },
});

const emit = defineEmits(['eventClicked']);

const calendarOptions = ref<CalendarOptions>({
  plugins: [dayGridPlugin, interactionPlugin, timeGridPlugin, rrulePlugin],
  timeZone: 'UTC',
  initialView: 'timeGridWeek',
  weekends: true,
  slotDuration: '1:00:00',
  slotEventOverlap: true,
  expandRows: true,
  height: 'auto',
  headerToolbar: false,
  allDayText: '',
  dayHeaderFormat: { weekday: 'long', day: 'numeric' },
  eventOrderStrict: true,
  eventTimeFormat: {
    hour: 'numeric',
    minute: '2-digit',
    meridiem: true,
  },
  events: [],
});

const fullCalendarRef = ref<InstanceType<typeof FullCalendar>>();

const currentCalendarMonth = ref<string>();

const months = ref<string[]>([]);

const selectedMonthDropdownRef = ref(null);
const monthDropdownActive = ref(false);

const contentPageStore = useContentPageStore();
const { contentPageHeaderHeight } = storeToRefs(contentPageStore);

const monthDropDownVisibilityHandler = computed({
  get() {
    return monthDropdownActive.value;
  },
  async set(newValue) {
    monthDropdownActive.value = newValue;
    if (newValue) {
      await nextTick();
      selectedMonthDropdownRef.value.$el?.children?.[0]?.click();
    }
  },
});

const handleMonthChange = (data) => {
  goToMonth(data);
  monthDropDownVisibilityHandler.value = false;
};

const {
  getMonths,
  fullCalendarViews,
  calendarViews,
  syncDate,
  goToMonth,
  toggleCalendarView,
  goToPrevPage,
  goToNextPage,
  goToTodaysPage,
} = useFullCalendarActions(fullCalendarRef, calendarOptions, currentCalendarMonth);
const currentCalendarView = ref(fullCalendarViews.WEEK);

watch(
  () => props.customCalendarOptions,
  (newVal) => {
    if (newVal) {
      const plugins = calendarOptions.value.plugins;
      const newOptions: CalendarOptions = props.customCalendarOptions;

      calendarOptions.value = {
        plugins,
        ...newOptions,
        eventClick(fcEvent) {
          emit('eventClicked', fcEvent?.event);
        },
      };
    }
  },
  { immediate: true },
);
watch(
  () => props.events,
  async (newVal) => {
    if (newVal) {
      calendarOptions.value.events = newVal;
    }
  },
  { immediate: true },
);

onMounted(() => {
  syncDate();
  months.value = getMonths();
});
// Style
const fullCalendarHeaderRef = ref();
const { height: fullCalendarHeaderHeight } = useElementSize(fullCalendarHeaderRef);
const calendarDateAxisTopPosition = computed(() => {
  // FC makes the Date axis sticky on default, this is to adjust its top pos from 0 to...
  return `${fullCalendarHeaderHeight.value + 1}px`;
});
const actionColumnHeight = computed(() => {
  const viewHeight = window.innerHeight;
  return `${viewHeight - contentPageHeaderHeight.value - fullCalendarHeaderHeight.value - 1}px`;
});
</script>

<style lang="scss">
.station-closure {
  opacity: 1 !important;
  background-size: auto auto;
  background-color: rgba(250, 250, 250, 1);
  border-color: white;
  background-image: repeating-linear-gradient(
    135deg,
    transparent,
    transparent 20px,
    rgba(223, 231, 239, 1) 20px,
    rgba(223, 231, 239, 1) 21px
  );
  cursor: pointer;
}
.fc {
  .fc-timegrid-col-bg {
    z-index: 4;
  }
  .fc-scroller {
    thead {
      > tr {
        > th {
          > div {
            border-top: solid 1px $shade300;
          }
        }
      }
    }
  }
  .fc-timegrid-slot-label {
    vertical-align: top !important;
  }
  .fc-scrollgrid-section-header.fc-scrollgrid-section-sticky > * {
    top: v-bind('calendarDateAxisTopPosition');
    &::before {
      // There is a 1px gap between FC header && our custom header.
      // Can see events scroll underneath it in said gap.
      // Removing 1px from calendarDateAxisTopPosition causes content shift.
      // This ::before hides that gap.
      content: '';
      height: 3px;
      top: -2px;
      background: white;
      position: absolute;
      width: 100%;
      left: 0;
    }
  }
  table {
    width: 100% !important;
  }
  .fc-col-header,
  .fc-timegrid-body,
  .fc-daygrid-body {
    width: 100% !important;
  }
}
.action-column {
  height: v-bind('actionColumnHeight') !important;
  max-height: v-bind('actionColumnHeight') !important;
  top: v-bind('calendarDateAxisTopPosition') !important;
}
.event-card-active {
  color: #282e38;
}
.event-card-inactive {
  color: $shade600;
}
</style>
