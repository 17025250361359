import { useTranslation } from '@centric-os/helpers';
import * as yup from 'yup';

export default () => {
  const { translate } = useTranslation();
  const validateEmail = yup
    .string()
    .email(`${translate('errorMessages.email')}`)
    .required(`${translate('errorMessages.required')}`);

  const authSchema = {
    email: validateEmail,
    password: yup.string().required(`${translate('errorMessages.required')}`),
  };

  const passwordResetSchema = {
    email: validateEmail,
  };

  return {
    authSchema,
    passwordResetSchema,
  };
};
