import { useToast } from 'primevue/usetoast';

/**
 * A composable providing toast notification functions for success, error, warning, and info messages,
 * with additional specialized notifications for scanner-related messages.
 *
 * @returns {{
 *   removeToasts: () => void,
 *   showErrorToast: (summary: string, detail?: string) => void,
 *   showSuccessToast: (summary: string, detail?: string) => void,
 *   showInfoToast: (summary: string, detail?: string) => void,
 *   showWarnToast: (summary: string, detail?: string) => void,
 *   showScannerSuccessToast: (summary: string, detail?: string) => void,
 *   showScannerErrorToast: (summary: string, detail?: string) => void,
 * }}
 */
export default () => {
  const toast = useToast();

  /**
   * Displays a success toast notification.
   * @param {string} summary - The main message for the toast notification.
   * @param {string} [detail] - Optional additional details for the toast notification.
   * @returns {void}
   */
  const showSuccessToast = (summary: string, detail?: string): void =>
    toast.add({
      group: 'success',
      life: 3000,
      severity: 'success',
      summary,
      ...(detail && { detail }),
    });

  /**
   * Displays an error toast notification.
   * @param {string} summary - The main message for the toast notification.
   * @param {string} [detail] - Optional additional details for the toast notification.
   * @returns {void}
   */
  const showErrorToast = (summary: string, detail?: string): void =>
    toast.add({
      group: 'error',
      life: 3000,
      severity: 'error',
      summary,
      ...(detail && { detail }),
    });

  /**
   * Displays an informational toast notification.
   * @param {string} summary - The main message for the toast notification.
   * @param {string} [detail] - Optional additional details for the toast notification.
   * @returns {void}
   */
  const showInfoToast = (summary: string, detail?: string): void =>
    toast.add({
      group: 'info',
      life: 3000,
      severity: 'info',
      summary,
      ...(detail && { detail }),
    });

  /**
   * Displays a warning toast notification.
   * @param {string} summary - The main message for the toast notification.
   * @param {string} [detail] - Optional additional details for the toast notification.
   * @returns {void}
   */
  const showWarnToast = (summary: string, detail?: string): void =>
    toast.add({
      group: 'warn',
      life: 3000,
      severity: 'warn',
      summary,
      ...(detail && { detail }),
    });

  /**
   * Displays a scanner-related success toast notification.
   * @param {string} summary - The main message for the toast notification.
   * @param {string} [detail] - Optional additional details for the toast notification.
   * @returns {void}
   */
  const showScannerSuccessToast = (summary: string, detail?: string): void =>
    toast.add({
      group: 'scanner-success',
      life: 3000,
      severity: 'success',
      summary,
      ...(detail && { detail }),
    });

  /**
   * Displays a scanner-related error toast notification.
   * @param {string} summary - The main message for the toast notification.
   * @param {string} [detail] - Optional additional details for the toast notification.
   * @returns {void}
   */
  const showScannerErrorToast = (summary: string, detail?: string): void =>
    toast.add({
      group: 'scanner-error',
      life: 3000,
      severity: 'error',
      summary,
      ...(detail && { detail }),
    });

  /**
   * Removes all toast notifications.
   * @returns {void}
   */
  const removeToasts = (): void => {
    toast.removeAllGroups();
  };

  return {
    removeToasts,
    showErrorToast,
    showSuccessToast,
    showInfoToast,
    showWarnToast,
    showScannerSuccessToast,
    showScannerErrorToast,
  };
};
