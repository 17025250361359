import { useBreakpoints } from '@centric-os/helpers/src';
import { ref, onMounted } from 'vue';

export default () => {
  const isMobile = ref(false);

  const breakpoints = useBreakpoints();
  const isSmall = breakpoints.smaller('sm');

  const checkIsMobile = () => {
    const userAgentMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent,
    );
    isMobile.value = userAgentMobile && isSmall.value;
  };

  onMounted(() => checkIsMobile());

  return { isMobile };
};
