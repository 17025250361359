<template>
  <Grid>
    <Col12>
      <PasswordField
        id="centricOs-new-password"
        name="password"
        :label="translate(`${LOCALE_PREFIX}.form.fields.newPassword`)"
        :placeholder="translate(`${LOCALE_PREFIX}.form.placeholder.newPassword`)"
        toggleMask
      />
    </Col12>
    <Col12>
      <PasswordField
        id="centricOs-confirm-password"
        name="confirmPassword"
        :label="translate(`${LOCALE_PREFIX}.form.fields.confirmNewPassword`)"
        :placeholder="translate(`${LOCALE_PREFIX}.form.placeholder.confirmNewPassword`)"
        toggleMask
        :disabled="!passwordFieldValue"
      />
    </Col12>
    <Col12 class="password-validator">
      <span>
        {{ translate(`${LOCALE_PREFIX}.requirement`) }}
      </span>
      <ul>
        <li v-for="(rule, index) in displayValidationRules" :key="index">
          <img :src="getIcon(rule.isValid)" alt="icon" />
          <span>{{ rule.message }}</span>
        </li>
      </ul>
    </Col12>
    <Col12>
      <Button
        id="centricOS-reset-password"
        type="submit"
        class="w-full"
        :disabled="!isValidForm"
        :label="translate(`${LOCALE_PREFIX}.buttons.resetPassword`)"
        @click.prevent="handleSubmit"
      />
    </Col12>
  </Grid>
</template>

<script lang="ts" setup>
import { useFieldValue } from 'vee-validate';
import { computed } from 'vue';
import CheckMark from '../../../../assets/CheckMark.svg';
import RedCancel from '../../../../assets/RedCancel.svg';
import GreyCancel from '../../../../assets/GreyCancel.svg';
import { useTranslation } from '@centric-os/helpers';
import { LoginLocalePrefix } from '../../../../enums';

const emit = defineEmits(['confirm']);

const getIcon = (isValid: boolean): string => {
  if (!passwordFieldValue.value) {
    return GreyCancel;
  }
  if (!passwordFieldValue.value && !passwordConfirmFieldValue.value) return GreyCancel;
  return isValid ? CheckMark : RedCancel;
};

const { translate } = useTranslation();

const LOCALE_PREFIX = LoginLocalePrefix.PASSWORD_RESET;

const passwordFieldValue = useFieldValue<string>('password');
const passwordConfirmFieldValue = useFieldValue<string>('confirmPassword');

const isMinLength = computed<boolean>(() => {
  return passwordFieldValue.value?.length >= 15;
});
const hasUppercase = computed<boolean>(() => {
  return /[A-Z]/.test(passwordFieldValue.value);
});
const hasLowercase = computed<boolean>(() => {
  return /[a-z]/.test(passwordFieldValue.value);
});
const hasNumber = computed<boolean>(() => {
  return /\d/.test(passwordFieldValue.value);
});
const hasSpecialChar = computed<boolean>(() => {
  return /[!@#$%^&*(),.?":{}|<>]/.test(passwordFieldValue.value);
});
const passwordsMatch = computed<boolean>(() => {
  return passwordFieldValue.value === passwordConfirmFieldValue.value;
});

const displayValidationRules = computed(
  (): Array<{ isValid: boolean; message: string }> => [
    {
      isValid: isMinLength.value,
      message: translate(`${LOCALE_PREFIX}.form.validation.minimumCharacters`),
    },
    {
      isValid: hasUppercase.value,
      message: translate(`${LOCALE_PREFIX}.form.validation.oneUpperCase`),
    },
    {
      isValid: hasLowercase.value,
      message: translate(`${LOCALE_PREFIX}.form.validation.oneLowerCase`),
    },
    {
      isValid: hasNumber.value || hasSpecialChar.value,
      message: translate(`${LOCALE_PREFIX}.form.validation.numberOrSpecialCharacter`),
    },
    {
      isValid: passwordsMatch.value,
      message: passwordsMatch.value
        ? translate(`${LOCALE_PREFIX}.form.validation.passwordsMatch`)
        : translate(`${LOCALE_PREFIX}.form.validation.passwordsNotMatch`),
    },
  ],
);

const isValidForm = computed((): boolean => {
  return (
    passwordFieldValue.value &&
    passwordConfirmFieldValue.value &&
    isMinLength.value &&
    hasUppercase.value &&
    hasLowercase.value &&
    (hasNumber.value || hasSpecialChar.value) &&
    passwordsMatch.value
  );
});

const handleSubmit = (): void => {
  emit('confirm', {
    password: passwordFieldValue.value,
    confirmPassword: passwordConfirmFieldValue.value,
  });
};
</script>
<style scoped lang="scss">
.password-validator ul {
  list-style-type: none;
  padding: 0;
}

.password-validator li {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin: 0.5rem 0;
  color: #000000;
}
</style>
