export enum TagComponentTypes {
  DELIVERY_APP_TAG = 'DeliveryAppTag',
  STATUS_TAG = 'StatusTag',
  ORDER_STATUS_TAG = 'OrderStatusTag',
  REFUND_STATUS_TAG = 'RefundStatusTag',
  TAG = 'Tag',
}

export enum TagComponentSeverities {
  SUCCESS = 'success',
  INFO = 'info',
  WARNING = 'warning',
  DANGER = 'danger',
}

export enum TagComponentCustomSeverityClasses {
  BETA = 'c-tag-beta',
  ORANGE = 'c-tag-orange',
}

export enum TagComponentIcons {
  SUCCESS = 'check',
  IN_PROGRESS = 'sync',
  DANGER = 'warning',
}
