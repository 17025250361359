<template>
  <FieldValidator :name="name">
    <template v-slot="{ value, handleChange, isInvalid, errorMessage }">
      <AutoComplete
        :modelValue="value"
        @update:modelValue="handleChange"
        :class="{ 'p-invalid': isInvalid }"
        v-bind="$attrs"
        ref="autoComplete"
      >
        <template v-if="label" #label>{{ label }}</template>
        <template v-if="startAdorment" #startAdorment>
          <i class="material-symbols-rounded text-2xl">{{ startAdorment }}</i>
        </template>
        <template v-if="isInvalid" #helperText>{{ errorMessage }}</template>
        <template v-if="$slots.option" #option="slotProps">
          <slot name="option" v-bind="slotProps"></slot>
        </template>
      </AutoComplete>
    </template>
  </FieldValidator>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import { AutoComplete } from './../../../core/controls';
import { FieldValidator } from './../field-validator';

defineProps({
  name: {
    type: String,
    required: true,
  },
  label: {
    type: String,
  },
  startAdorment: {
    type: String,
  },
});

const autoComplete = ref<InstanceType<typeof AutoComplete>>();

defineExpose({ autoComplete });
</script>

<script lang="ts">
export default {
  name: 'AutocompleteField',
  inheritAttrs: false,
};
</script>
