import { useAuthStore, useMainNavigationStore } from '@centric-os/stores';
import type { NavigationGuardNext, RouteLocationNormalized } from 'vue-router';
import { SitesRouteNames } from '@centric-os/sites';
import { StockRouteNames } from '../../enums';

/**
 * Navigation guard for the Stock route.
 *
 * @param to - The target route being navigated to.
 * @param from - The current route being navigated away from.
 * @param next - The function to resolve the navigation.
 */

export const beforeSitesListEnter = async (
  _to: RouteLocationNormalized,
  _from: RouteLocationNormalized,
  next: NavigationGuardNext,
) => {
  try {
    const navStore = useMainNavigationStore();
    const authStore = useAuthStore();

    // Can access = Admin || Site Operator
    const canAccess = navStore.canAccessStock;
    if (!canAccess) {
      return next({ name: SitesRouteNames.SITES_LIST, replace: true });
    }

    // Redirect to STOCK_BUSINESS_UNIT if user is a single site operator
    if (authStore.isSiteOperatorSingleSite) {
      return next({
        name: StockRouteNames.STOCK_BUSINESS_UNIT,
        params: { id: authStore.getUserAllowedResourceIds[0] },
        replace: true,
      });
    }

    // Proceed with the navigation if no redirection is needed
    next();
  } catch (error) {
    console.error('Error during navigation guard execution:', error);
    next(false); // Cancel navigation in case of an error
  }
};

export const beforeBusinessUnitEnter = async (
  _to: RouteLocationNormalized,
  _from: RouteLocationNormalized,
  next: NavigationGuardNext,
) => {
  try {
    const navStore = useMainNavigationStore();

    // Can access = Admin || Site Operator
    const canAccess = navStore.canAccessStock;
    if (!canAccess) {
      return next({ name: SitesRouteNames.SITES_LIST, replace: true });
    }

    // Proceed with the navigation if no redirection is needed
    next();
  } catch (error) {
    console.error('Error during navigation guard execution:', error);
    next(false); // Cancel navigation in case of an error
  }
};
