<template>
  <TextField
    name="email"
    :label="translate(`${LOCALE_PREFIX}.form.fields.email`)"
    :placeholder="translate(`${LOCALE_PREFIX}.form.fields.email`)"
  />
</template>

<script setup lang="ts">
import { LoginLocalePrefix } from '../../../enums';
import { useTranslation } from '@centric-os/helpers';

const { translate } = useTranslation();
const LOCALE_PREFIX = LoginLocalePrefix.LOGIN;
</script>
