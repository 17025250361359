<template>
  <Form :form-id="'login'" :showValidationDialog="false" ref="formComponent" :schema="schema">
    <template v-slot="{ isFormValid, form }">
      <form @submit.prevent="authenticate(form.values)">
        <div class="grid" v-if="!authenticating">
          <div class="col-12">
            <EmailField />
          </div>

          <div class="col-12">
            <PasswordField />
          </div>

          <div class="col-12">
            <Button
              id="centricOS-login-button"
              type="submit"
              class="w-full"
              :disabled="!isFormValid"
              :label="translate(`${LOCALE_PREFIX}.form.buttons.p2Login`)"
            />
          </div>
        </div>

        <div class="grid text-center mt-4" v-else>
          <div class="col-12">
            <ProgressSpinner strokeWidth="8" />
          </div>

          <div class="col-12">
            <span>{{ translate(`${LOCALE_PREFIX}.infoPassword.authenticating`) }}</span>
          </div>
        </div>
      </form>
    </template>
  </Form>
</template>

<script setup lang="ts">
import PasswordField from '../password';
import EmailField from '../email';
import { useRouter } from 'vue-router';
import { useAuthStore } from '@centric-os/stores';
import { LoginLocalePrefix } from '../../../enums';
import { useAuthSchema, useUserAgent } from '../../composables';
import { heap, useTranslation } from '@centric-os/helpers';
import { ref } from 'vue';
import { Form, useGlobalToast } from '@centric-os/components';
import type { CdlError } from '@centric-os/types';
import { SitesRouteNames } from '@centric-os/sites/src/enums';
import { storeToRefs } from 'pinia';

const { translate } = useTranslation();
const formComponent = ref<InstanceType<typeof Form>>();
const LOCALE_PREFIX = LoginLocalePrefix.LOGIN;
const router = useRouter();

const authStore = useAuthStore();

const { authSchema: schema } = useAuthSchema();
const { isMobile } = useUserAgent();

const { showErrorToast } = useGlobalToast();

const authenticating = ref(false);
const authenticate = async ({ email, password }) => {
  authenticating.value = true;
  authStore
    .p2Authenticate(email, password)
    .then(handleSuccess)
    .catch((err) => {
      authenticating.value = false;
      handleError(err);
    });
};

const handleSuccess = async () => {
  if (isMobile.value) authStore.loggedInOnMobile = true;

  await authStore.getPermissions(authStore.userId);
  const { cdlUser, usedSSO } = storeToRefs(authStore);

  heap().init(cdlUser, usedSSO, authStore.getRole, authStore.hasRole);

  router.push({
    name: authStore.intendedRoute?.name || SitesRouteNames.SITES_LIST,
    params: authStore.intendedRoute?.params,
  });
  authStore.isPasswordReset = false;
  authStore.intendedRoute = null;
};

const handleError = (err: CdlError) => {
  showErrorToast(err.message);
  const passwordError = getErrorMessage(err);
  formComponent.value.form.setErrors({
    password: passwordError,
  });
};

const getErrorMessage = (err: CdlError) => {
  let passwordError = '';
  if (err.status === 401) {
    passwordError = err.message;
  } else if (err.status === 401.3) {
    passwordError = translate(`${LOCALE_PREFIX}.form.errors.credentialsNotMatched`);
  } else {
    passwordError = translate(`${LOCALE_PREFIX}.form.errors.unexpectedError`);
  }
  return passwordError;
};
</script>
