<template>
  <Form
    :form-id="'password-reset-email'"
    :showValidationDialog="false"
    ref="formComponent"
    :schema="schema"
  >
    <template v-slot="{ isFormValid, form }">
      <form @submit.prevent="onSendResetEmail(form.values)">
        <Grid v-if="!processing">
          <Col12>
            <EmailField
              id="centricOs-reset-email"
              :label="translate(`${LOCALE_PREFIX}.form.fields.email`)"
              v-model="form.values.email"
            />
          </Col12>
          <Col12>
            <Button
              id="centricOS-reset-email-button"
              type="submit"
              class="w-full"
              :disabled="!isFormValid"
              :label="translate(`${LOCALE_PREFIX}.buttons.sendResetLink`)"
            />
          </Col12>
        </Grid>

        <Grid class="text-center mt-4" v-else>
          <Col12>
            <ProgressSpinner strokeWidth="8" />
          </Col12>
          <Col12>
            <span>{{ translate(`${LOCALE_PREFIX}.info.sendingEmail`) }}</span>
          </Col12>
        </Grid>
      </form>
    </template>
  </Form>
</template>

<script setup lang="ts">
import EmailField from '../email';
import { Col12, Form, Grid } from '@centric-os/components';
import { useAuthSchema } from '../../composables';
import { LoginLocalePrefix } from '../../../enums';
import { useTranslation } from '@centric-os/helpers';

defineProps<{
  onSendResetEmail: (data: { email: string }) => Promise<void>;
  processing: boolean;
}>();

const { translate } = useTranslation();
const LOCALE_PREFIX = LoginLocalePrefix.PASSWORD_RESET;
const { passwordResetSchema: schema } = useAuthSchema();
</script>
