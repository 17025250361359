export enum FinancialFormIDs {
  PAYMENT_INFO_FORM = 'paymentInfoForm',
  MEAL_PLAN_FORM = 'mealPlanForm',
  MEAL_PLAN_DOMAINS_FORM = 'mealPlanDomainsForm',
  TENDER_SETUP_FORM = 'tenderSetupForm',
  CASHLESS_PAY_FORM = 'cashlessPayForm',
}

export enum PaymentConfigTypes {
  FREEDOM_PAY = 'freedompay',
}

export const MEALPLAN_TYPES = {
  BLACKBOARD: 'BLACKBOARD',
  BLACKBOARD_SAAS: 'BLACKBOARD SAAS',
  CBORD: 'CBORD',
  ITCSTANDARD: 'ITCSTANDARD',
  ITCDIETARYRESTRICTION: 'ITCDIETARYRESTRICTION',
  ITCLUNCHBOX: 'ITCLUNCHBOX',
  ITCSSO: 'ITCSSO',
  HEARTLAND: 'HEARTLAND',
  ATRIUM: 'ATRIUM',
  ATRIUM_SSO: 'ATRIUM_SSO',
  CBORD_DIRECT: 'CBORD DIRECT',
};

export const DEFAULT_VENDOR_IDs = {
  ITC: '00000G0SMYPTYJ5LP1Z3C9E2LH5OBCPJ',
};

export const TENDER_TYPES = {
  MEAL_EQUIVALENT: 'meal_equivalent',
  DECLINING_BALANCE: 'declining_balance',
  MEAL_EXCHANGE: 'meal_exchange',
};

export const CASHLESS_TENDER_TYPES = {
  BADGE_PAY: 'badge_pay',
  COUPON_VOUCHER: 'coupon_voucher',
  VOUCHER: 'voucher',
  STIPEND: 'stipend',
};

export const CASHLESS_PAY_TYPES = {
  FREEDOM_PAY: 'freedompay',
};

export const PAYMENT_CONFIG_TYPES = {
  FREEDOM_PAY: 'freedompay',
};

// Define ITC types
export const ITC_TYPES = [
  MEALPLAN_TYPES.ITCSTANDARD,
  MEALPLAN_TYPES.ITCDIETARYRESTRICTION,
  MEALPLAN_TYPES.ITCLUNCHBOX,
  MEALPLAN_TYPES.ITCSSO,
];

// Freedom pay payment config types
export const FREEDOM_PAY_CONFIG_TYPES = [PAYMENT_CONFIG_TYPES.FREEDOM_PAY];

// Freedom pay cashless types
export const FREEDOM_PAY_TYPES = [CASHLESS_PAY_TYPES.FREEDOM_PAY];

// Define Atrium types
export const ATRIUM_TYPES = [MEALPLAN_TYPES.ATRIUM, MEALPLAN_TYPES.ATRIUM_SSO];

// Define TouchNet types
export const TOUCHNET_TYPES = [MEALPLAN_TYPES.HEARTLAND];

// Define Transact (Blackboard) types
export const TRANSACT_TYPES = [MEALPLAN_TYPES.BLACKBOARD, MEALPLAN_TYPES.BLACKBOARD_SAAS];

// Define CBORD types
export const CBORD_TYPES = [MEALPLAN_TYPES.CBORD, MEALPLAN_TYPES.CBORD_DIRECT];
