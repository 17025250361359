import type { RecordEntityId } from '../records';
import type { Site } from '../site';

export interface GetDiscountResponse {
  discount: {
    id: string;
    createdAt: string;
    updatedAt: string;
    createdBy: string;
    updatedBy: string;
    name: string;
    is: DiscountIs;
    meta: {
      provider: {
        voucherify: {
          discount: DiscountMeta;
        };
      };
    };
  };
  sites: DiscountSite[];
}

export interface PublishDiscountResponse {
  meta: DiscountMeta;
  sites: DiscountSite[];
}
export interface PutDiscountResponse {
  discount: Discount;
  sites: string[];
}
export interface DiscountsResponse {
  discounts: Discount[];
}

export interface Discount {
  id?: string;
  visible?: boolean;
  status?: DiscountStatus;
  name: string;
  type?: string;
  value?: number;
  assign_to?: string;
  applies_to?: DiscountSite[];
  is?: DiscountIs;
  meta?: DiscountMeta;

  // Additional properties for columns handled by BE
  createdAt?: string;
  updatedAt?: string;
  createdBy?: string;
  updatedBy?: string;
}

export interface SiteAdditionFormFields {
  site: Site;
}

export interface DiscountSite {
  id: RecordEntityId;
  name: string;
}

export interface DiscountStatus {
  live?: boolean;
  publishedTo3rdParty?: boolean;
  readyToPublish?: boolean;
}

export interface DiscountIs {
  badgepayPromo?: boolean;
  mealplanPromo?: boolean;
}

export interface DiscountMeta {
  type: string;
  amountOff?: number;
  percentOff?: number;
}

export interface DiscountSchedule {
  start_datetime: Date;
  end_datetime: Date;
  hours: string;
  start_time?: Date;
  end_time?: Date;
  days: DiscountDays;
}

export interface DiscountDays {
  monday: boolean;
  tuesday: boolean;
  wednesday: boolean;
  thursday: boolean;
  friday: boolean;
  saturday: boolean;
  sunday: boolean;
}

// Enum for column keys
export enum DiscountColumnKeys {
  NAME = 'name',
  STATUS = 'status',
  VISIBLE = 'visible',
  SCHEDULE_START = 'scheduleStart',
  SCHEDULE_END = 'scheduleEnd',
  SITES = 'sites',
  BRANDS = 'brands',
  VALUE = 'value',
  APPLIES_TO = 'applies_to',
  CREATED = 'created',
  CREATED_BY = 'created_by',
  UPDATED = 'updated',
  UPDATED_BY = 'updated_by',
}

export enum DiscountViews {
  LIVE = 'live',
  PAST = 'past',
  DRAFT = 'draft',
}

export enum DiscountStatuses {
  LIVE = 'live',
  SCHEDULED = 'scheduled',
  READY = 'ready_to_publish',
  INCOMPLETE = 'incomplete',
  PAST = 'expired',
  PAUSED = 'paused',
}
