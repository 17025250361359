import type { RouteRecordRaw } from 'vue-router';
import { buildChildURL } from '@centric-os/helpers';
import { DiscountsRouteNames, DiscountsRoutePathNames } from '../enums';
import { beforeEnter } from './guards/index';

const List = () => import('../components/list/List.vue');
const Create = () => import('../components/create/Create.vue');
const Update = () => import('../components/update/Update.vue');

const listRoute = {
  path: buildChildURL(DiscountsRoutePathNames.LIST),
  name: DiscountsRouteNames.DISCOUNTS_LIST,
  component: List,
  beforeEnter: beforeEnter,
} as RouteRecordRaw;

const createRoute = {
  path: buildChildURL(DiscountsRoutePathNames.CREATE),
  name: DiscountsRouteNames.DISCOUNTS_CREATE,
  component: Create,
  beforeEnter: beforeEnter,
} as RouteRecordRaw;

const updateRoute = {
  path: buildChildURL(DiscountsRoutePathNames.UPDATE),
  name: DiscountsRouteNames.DISCOUNTS_UPDATE,
  component: Update,
  beforeEnter: beforeEnter,
} as RouteRecordRaw;

const childRoutes: RouteRecordRaw[] = [listRoute, createRoute, updateRoute];

export default childRoutes;
