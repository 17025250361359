import { type StateTree, defineStore } from 'pinia';
import { useAuthStore } from '../use-auth-store';
import { type RecordEntityId } from '@centric-os/types';

export const menusPermissionScope = {
  readGlobalMenuGroups: 'read:global_menu_group:*',
  writeGlobalMenuGroups: 'write:global_menu_group:*',
  writeNestedGlobalMenuGroups: 'write_nested:global_menu_group:',
  fullGlobalMenuGroups: '*:global_menu_group:*',
  fullLocalMenuGroup: '*:local_menu_group:*',
  writeLocalMenuGroup: 'write:local_menu_group:*',
  writeNestedLocalMenuGroup: 'write_nested:local_menu_group:',
  readLocalMenuGroup: 'read:local_menu_group:*',
  readAssignedLocalMenuGroup: 'read:local_menu_group:',
  writeNestedPublishLocalMenuGroupAdmin: 'write_nested_publish:local_menu_group:.*',
  writeNestedPublishLocalMenuGroup: 'write_nested_publish:local_menu_group',
  writeNestedIsActiveLocalMenuGroupAdmin: 'write_nested_is_active:local_menu_group:*',
  writeNestedIsActiveLocalMenuGroup: 'write_nested_is_active:local_menu_group',
};

interface State extends StateTree {
  brandGroupId: RecordEntityId;
  menuId: RecordEntityId;
}

export const useMenuPermissionsStore = defineStore('menuPermissions', {
  state: (): State => ({
    brandGroupId: null,
    menuId: null,
  }),
  getters: {
    canAccessBrandsSection(): boolean {
      const authStore = useAuthStore();
      return (
        authStore.hasPermissions([menusPermissionScope.fullGlobalMenuGroups]) ||
        authStore.hasPermissions([menusPermissionScope.readGlobalMenuGroups])
      );
    },
    canWriteBrandGroups(): boolean {
      const authStore = useAuthStore();
      return (
        authStore.hasPermissions([menusPermissionScope.writeGlobalMenuGroups]) ||
        authStore.hasPermissions([menusPermissionScope.fullGlobalMenuGroups])
      );
    },
    canWriteSubComponents(): boolean {
      if (this.brandGroupId) {
        return this.canWriteBrandGroupsSubComponents;
      } else if (this.menuId) {
        return this.canWriteLocalMenuSubComponents;
      } else {
        return false;
      }
    },
    canWriteBrandGroupsSubComponents(): boolean {
      const authStore = useAuthStore();
      return (
        authStore.hasPermissions([menusPermissionScope.writeGlobalMenuGroups]) ||
        authStore.hasPermissions([menusPermissionScope.fullGlobalMenuGroups]) ||
        authStore.hasPermissions([
          `${menusPermissionScope.writeNestedGlobalMenuGroups}${this.brandGroupId}`,
        ])
      );
    },
    canAccessMenusSection(): boolean {
      const authStore = useAuthStore();
      return (
        authStore.hasPermissions([menusPermissionScope.readLocalMenuGroup]) ||
        authStore.hasPermissions([menusPermissionScope.readAssignedLocalMenuGroup]) ||
        authStore.hasPermissions([menusPermissionScope.fullLocalMenuGroup])
      );
    },
    canWriteMenus(): boolean {
      const authStore = useAuthStore();
      return (
        authStore.hasPermissions([menusPermissionScope.writeLocalMenuGroup]) ||
        authStore.hasPermissions([menusPermissionScope.fullLocalMenuGroup])
      );
    },
    canWriteLocalMenuSubComponents(): boolean {
      const authStore = useAuthStore();
      return (
        authStore.hasPermissions([menusPermissionScope.writeLocalMenuGroup]) ||
        authStore.hasPermissions([menusPermissionScope.fullGlobalMenuGroups]) ||
        authStore.hasPermissions([
          `${menusPermissionScope.writeNestedLocalMenuGroup}${this.menuId}`,
        ])
      );
    },
    canPublishLmgBrand(): boolean {
      const authStore = useAuthStore();

      return (
        this.canWriteLocalMenuSubComponents ||
        authStore.hasPermissions([
          `${menusPermissionScope.writeNestedPublishLocalMenuGroupAdmin}`,
        ]) ||
        authStore.hasPermissions([
          `${menusPermissionScope.writeNestedPublishLocalMenuGroup}${this.menuId}`,
        ])
      );
    },
    canToggleLmgItemOrModifierActive(): boolean {
      const authStore = useAuthStore();

      return (
        this.canWriteLocalMenuSubComponents ||
        authStore.hasPermissions([
          `${menusPermissionScope.writeNestedIsActiveLocalMenuGroupAdmin}`,
        ]) ||
        authStore.hasPermissions([
          `${menusPermissionScope.writeNestedIsActiveLocalMenuGroup}${this.menuId}`,
        ])
      );
    },
    isGlobalBrand: (state) => {
      return state.brandGroupId && !state.menuId;
    },
  },
});
