import { buildChildURL } from '@centric-os/helpers';
import type { RouteRecordRaw } from 'vue-router';

import { GlobalMenuGroupsRouteNames, GlobalMenuGroupsRoutePathNames } from '../../enums';

const Component = () => import('./ManageBrandSubEntity.vue');

export default [
  {
    path: buildChildURL(
      GlobalMenuGroupsRoutePathNames.ID,
      GlobalMenuGroupsRoutePathNames.BRAND_DETAILS,
      GlobalMenuGroupsRoutePathNames.BRAND_ID,
      GlobalMenuGroupsRoutePathNames.ENTITY,
      GlobalMenuGroupsRoutePathNames.ENTITY_ID,
      GlobalMenuGroupsRoutePathNames.MODE,
    ),
    name: GlobalMenuGroupsRouteNames.ENTITY_MANAGEMENT,
    component: Component,

    children: [],
  },
  // Route for an item opened from a Category

  {
    path: buildChildURL(
      GlobalMenuGroupsRoutePathNames.ID,
      GlobalMenuGroupsRoutePathNames.BRAND_DETAILS,
      GlobalMenuGroupsRoutePathNames.BRAND_ID,
      GlobalMenuGroupsRoutePathNames.MENUS,
      GlobalMenuGroupsRoutePathNames.MENU_ID,
      GlobalMenuGroupsRoutePathNames.CATEGORY,
      GlobalMenuGroupsRoutePathNames.CATEGORY_ID,
      GlobalMenuGroupsRoutePathNames.ENTITY,
      GlobalMenuGroupsRoutePathNames.ENTITY_ID,
      GlobalMenuGroupsRoutePathNames.MODE,
    ),
    name: GlobalMenuGroupsRouteNames.CATEGORY_CHILD_MANAGEMENT,
    component: Component,

    children: [],
  },
  // When we do Modifiers, we can do Modifier group below with the same component
] as RouteRecordRaw[];
