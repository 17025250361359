// import { useGlobalToast } from "@centric-os/components";
import type { ApiResponseError } from '@centric-os/types';
import { onErrorCaptured } from 'vue';
import { useI18n } from 'vue-i18n';

export default (showErrorToast: Function) => {
  const { t } = useI18n();

  onErrorCaptured((error: ApiResponseError) => {
    if (error.status) {
      if (error.status === 400) {
        showErrorToast(error?.error ? error?.error : error?.message);
      } else {
        showErrorToast(t(`errorMessages.genericApiError`));
      }

      return false;
    }
    return true;
  });

  return {};
};
