<template>
  <div v-if="loading" class="lds-ripple">
    <div></div>
    <div></div>
  </div>
</template>

<script setup lang="ts">
import { useLoadingStore } from '@centric-os/stores';
import { storeToRefs } from 'pinia';

const loadingStore = useLoadingStore();
const { loading } = storeToRefs(loadingStore);
</script>

<script lang="ts">
export default {
  name: 'LoadingApi',
};
</script>

<style scoped lang="scss">
@keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 0;
  }
  4.9% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 0;
  }
  5% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}
.lds-ripple {
  // display: inline;
  position: fixed;
  // top: -6.5rem;
  right: 6rem;
  bottom: 6rem;
  z-index: 99999;

  div {
    position: absolute;
    border: 4px solid $primaryColor;
    opacity: 1;
    border-radius: 50%;
    animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;

    &:nth-child(2) {
      animation-delay: -0.5s;
    }
  }
}
</style>
