import type { RouteRecordRaw } from 'vue-router';
import { buildRootURL } from '@centric-os/helpers';
import { LoginRouteNames, LoginRoutePathNames } from '../enums';
import LoginFormLayout from './components/login-form-layout/LoginFormLayout.vue';
import PasswordResetLayout from './components/password-reset-layout/PasswordResetLayout.vue';

const App = () => import('./App.vue');

export const route: RouteRecordRaw = {
  path: buildRootURL(''),
  component: App,
  children: [
    {
      path: LoginRoutePathNames.LOGIN,
      name: LoginRouteNames.LOGIN,
      component: LoginFormLayout,
    },
    {
      path: LoginRoutePathNames.PASSWORD_RESET,
      name: LoginRouteNames.PASSWORD_RESET,
      component: PasswordResetLayout,
    },
  ],
};
