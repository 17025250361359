<template>
  <Page class="px-6 w-full" :title="translate(`${LOCALE_PREFIX}.pageTitle`)">
    <div class="flex flex-column align-items-center justify-content-center gap-2 mt-5 mb-3">
      <h1 class="font-semibold">
        {{ header }}
      </h1>
      <p
        v-if="!isSendPasswordLink && !isUserVerified"
        class="font-sm text-base email-instructions-color no-line-height"
      >
        {{ translate(`${LOCALE_PREFIX}.info.emailResetInstructions`) }}
      </p>
    </div>

    <template v-if="isUserVerified">
      <ChangePasswordForm :onSendResetPassword="sendResetPassword" />
    </template>

    <template v-else>
      <template v-if="!isSendPasswordLink">
        <PasswordResetForm :on-send-reset-email="sendResetEmail" :processing="processing" />
      </template>

      <template v-else>
        <div class="flex flex-column mb-3 email-instructions-color">
          <span class="font-sm text-base">{{
            translate(`${LOCALE_PREFIX}.info.sentInstructionsTo`)
          }}</span>
          <span class="submitted-email font-sm text-base font-bold">{{ submittedEmail }}</span>
          <p class="font-sm text-base">
            {{ translate(`${LOCALE_PREFIX}.info.waitForArrivalCheckSpam`) }}
          </p>
        </div>
      </template>

      <template v-if="!isSendPasswordLink">
        <GoToSso />
      </template>

      <template v-else>
        <div class="flex align-items-center justify-content-center w-full">
          <Button
            id="centricOS-resend-reset-link-button"
            class="w-full"
            :label="translate(`${LOCALE_PREFIX}.buttons.resendResetLink`)"
            @click="resendEmail({ email: submittedEmail })"
          />
        </div>

        <div class="flex align-items-center justify-content-center mt-3 w-full">
          <Divider align="center" type="solid">
            <span class="uppercase">{{ translate(`${LOCALE_PREFIX}.orHelperText`) }}</span>
          </Divider>
        </div>

        <div class="flex align-items-center justify-content-center">
          <Button
            id="centricOS-go-back-button"
            :class="`${ButtonStateClass.LINK}`"
            :label="translate(`${LOCALE_PREFIX}.buttons.goBackChangeEmail`)"
            @click="backToChangeEmail"
          >
            <template #icon>
              <i class="material-symbols-rounded mr-2">arrow_back</i>
            </template>
          </Button>
        </div>
      </template>
    </template>
  </Page>
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from 'vue';
import { useAuthStore, useSplitIoStore } from '@centric-os/stores';
import { LoginLocalePrefix, LoginRouteNames } from '../../../enums';
import PasswordResetForm from '../password-reset-form/PasswordResetForm.vue';
import { ButtonStateClass, useGlobalToast } from '@centric-os/components';
import { useGenericAPIErrorToast, useTranslation } from '@centric-os/helpers';
import GoToSso from '../go-to-sso/GoToSso.vue';
import ChangePasswordForm from '../change-password-form/ChangePasswordForm.vue';
import { useRouter } from 'vue-router';
import { FeatureFlags } from '@centric-os/types';

const authStore = useAuthStore();
const splitStore = useSplitIoStore();
const { translate } = useTranslation();
const router = useRouter();

const isSendPasswordLink = ref<boolean>(false);
const submittedEmail = ref<string | null>(null);
const isUserVerified = ref<boolean>(false);
const realm = ref<string | null>(null);
const userId = ref<string | null>(null);
const resetToken = ref<string | null>(null);
const stage = ref<string | null>(null);
const role = ref<string | null>(null);
const LOCALE_PREFIX = LoginLocalePrefix.PASSWORD_RESET;
defineExpose({ submittedEmail, isSendPasswordLink });

const header = computed<string>(() => {
  let translatedText: string;

  if (isSendPasswordLink.value) {
    translatedText = translate(`${LOCALE_PREFIX}.info.checkYourEmail`);
  } else if (isUserVerified.value) {
    translatedText = translate(`${LOCALE_PREFIX}.resetPasswordTitle`);
  } else {
    translatedText = translate(`${LOCALE_PREFIX}.forgotPasswordTitle`);
  }

  return translatedText;
});

const { showErrorToast, showSuccessToast } = useGlobalToast();
const processing = ref<boolean>(false);

const sendResetEmail = async (formData: { email: string }): Promise<void> => {
  submittedEmail.value = formData.email;
  processing.value = true;
  try {
    await authStore.sendUserForgotPassword({ email: formData.email });
    isSendPasswordLink.value = !isSendPasswordLink.value;
    handleSuccess(`${LOCALE_PREFIX}.form.success.emailSent`);
    processing.value = false;
  } catch (err: unknown) {
    processing.value = false;
    showErrorToast(
      (err instanceof Error ? err.message : undefined) ||
        translate(`${LOCALE_PREFIX}resetToken.form.errors.unexpectedError`),
    );
    throw err;
  }
};

const resendEmail = async ({ email }: { email: string }): Promise<void> => {
  try {
    await authStore.sendUserForgotPassword({ email });
    handleSuccess(`${LOCALE_PREFIX}.form.success.emailSent`);
  } catch (err: unknown) {
    showErrorToast(
      (err instanceof Error ? err.message : undefined) ||
        translate(`${LOCALE_PREFIX}.form.errors.unexpectedError`),
    );
    throw err;
  }
};

const sendResetPassword = async ({
  password,
  confirmPassword,
}: {
  password: string;
  confirmPassword: string;
}): Promise<void> => {
  try {
    await authStore.resetPassword({
      newPassword: confirmPassword,
      resetToken: resetToken.value,
      userId: userId.value,
      realm: realm.value,
      stage: stage.value,
      role: role.value,
    });
    authStore.isPasswordReset = true;
    handleSuccess(`${LOCALE_PREFIX}.form.success.passwordReset`);
    router.push('/login');
  } catch (err: unknown) {
    showErrorToast(
      (err instanceof Error ? err.message : undefined) ||
        translate(`${LOCALE_PREFIX}.form.errors.unexpectedError`),
    );
    throw err;
  }
};

const handleSuccess = async (successMessageKey: string): Promise<void> => {
  showSuccessToast(translate(successMessageKey));
};

useGenericAPIErrorToast(showErrorToast);

const backToChangeEmail: () => void = () => {
  isSendPasswordLink.value = !isSendPasswordLink.value;
};

onMounted(async (): Promise<void> => {
  try {
    if (!splitStore.initialized) {
      await splitStore.fetchFeatureFlags();
    }
    if (!splitStore.isFeatureOn(FeatureFlags.CENTRIC_PASSWORD_RESET)) {
      router.push({ name: LoginRouteNames.LOGIN });
      return;
    }

    const urlParams = new URLSearchParams(window.location.search);
    const userVerified = urlParams.get('user_verified');
    userId.value = urlParams.get('id');
    resetToken.value = urlParams.get('reset_token');
    realm.value = urlParams.get('realm');
    stage.value = urlParams.get('stage');
    role.value = urlParams.get('role');
    authStore.isPasswordReset = false;

    if (userVerified === null) {
      isUserVerified.value = false;
    } else {
      isUserVerified.value = true;
    }
  } catch (err: unknown) {
    showErrorToast(translate(`${LOCALE_PREFIX}.form.errors.unexpectedError`));
    throw err;
  }
});
</script>

<style lang="scss" scoped>
.email-instructions-color {
  color: $neutralDarkD2Color;
}

.no-line-height {
  line-height: 0;
}
</style>
