export enum FeatureFlags {
  ALLOW_SCHEDULE_WITHOUT_MOBILE_CDL_21573 = 'ALLOW-SCHEDULE-WITHOUT-MOBILE-CDL-21573',
  AUDIT_TABLE_V2 = 'CDL-20081-audit-table-v2',
  AUTO_PUSH_TOGGLE = 'CDL-22813-auto-push-toggle',
  BRAND_JDE_CATEGORY = 'Brand-JDE-Category',
  BUSINESS_UNIT_COPY_ID = 'BUSINESS_UNIT_COPY_ID',
  CANADA_REFUNDS_ENABLED = 'CDL-10248-allow-canada-refunds',
  CENTRIC_HEADER_REDESIGN = 'centric-header-redesign',
  CENTRIC_PASSWORD_RESET = 'CENTRIC_PASSWORD_RESET',
  CPG_ITEM_UPLOAD = 'CDL-18946-CPG-upload',
  CPG_POST_PILOT_UPDATES = 'cpg-post-pilot-updates',
  CPG_SCANNER_LIBRARY_UPDATE = 'CPG-Scanner-Library-Update',
  EXPORT_IMPORT_BRAND = 'CDL-21748-import-export-brand',
  EXPORT_MENU_GROUP = 'CDL-19927-Export-Group',
  GENERATIVE_AI = 'generative-ai',
  HIDE_DEVICE_MAPPING = 'Hide-Device-Mapping',
  HIDE_IMPORT_EXPORT_ITEMS = 'Hide-Import-Export-Items',
  HIDE_SALES_CHANNEL = 'Hide-Sales-Channel',
  ITEM_AND_MODIFIER_BULK_DELETE = 'CDL-18713-Item-Modifier-Bulk-Delete',
  ITEM_MANAGEMENT_PAGE = 'item-management-page',
  JWO_MENU_INTEGRATION = 'JWO-Menu-Integration',
  LUNA = 'luna',
  LUNA_MENUWORKS = 'luna-menuworks',
  MANAGE_ANNOUNCEMENTS = 'MANAGE_ANNOUNCEMENTS',
  MANAGE_BUSINESS_UNIT = 'MANAGE_BUSINESS_UNIT',
  MANAGE_CREATE_SITE = 'MANAGE_CREATE_SITE',
  MANAGE_CREATE_STATION = 'MANAGE_CREATE_STATION',
  MANAGE_MARKETING = 'MANAGE-MARKETING',
  MANAGE_SITE_PAYMENT_INFO = 'MANAGE_SITE_PAYMENT_INFO',
  MANAGE_SITE_MEAL_PLANS = 'MANAGE_SITE_MEAL_PLANS',
  MANAGE_STATION_EDIT = 'MANAGE_STATION_EDIT',
  MENU_SIDEBAR_UI_UPDATES = 'CDL-19152-menu-sidebar-updates',
  MENU_STATUS_NEXTEP = 'MENU-STATUS-NEXTEP',
  MENU_STATUS_VOLANTE = 'MENU-STATUS-VOLANTE',
  MENU_STATUS_AGILYSYS = 'MENU-STATUS-AGILYSYS',
  MENU_STATUS_TIME2EAT = 'MENU-STATUS-TIME2EAT',
  MENUWORKS_V2 = 'CDL-19289-Menuworks-v2',
  MODIFIER_TABLE_V2 = 'MODIFIER-TABLE-V2',
  NEXTEP_DEVICE_MAPPING = 'nextep-device-mapping',
  NEXTEP_STATION_ALLOW_DISABLE_MENU = 'Nextep-Station-Allow-Disable-Menu',
  PRICE_LEVELS = 'PRICE-LEVELS',
  SCANNER = 'CDL-18134-scanner',
  SHOW_REVIEWS = 'SHOW-REVIEWS',
  SHOW_SITE_KDS = 'SHOW_SITE_KDS',
  SITE_COPY_ID = 'SITE_COPY_ID',
  SITE_FINANCIAL_CONFIG = 'SITE_FINCANCIAL_CONFIG',
  SITE_MANAGE_CASHLESS_BADGE_PAY = 'SITE_MANAGE_CASHLESS_BADGE_PAY',
  STATION_MEALPLAN_CONFIG = 'STATION_MEALPLAN_CONFIG',
  STOCK_MODULE = 'stock-module',
  TAX_TAG_CODE = 'tax_tag_code',
  TERMINAL_SALES = 'CDL-18787-terminal-sales-report',
  TIME2EAT_TOGGLE = 'TIME2EAT_TOGGLE',
  USE_BRAND_PREPUBLISH_VALIDATION = 'CDL-24341-USE_BRAND_PREPUBLISH_VALIDATION',
  VOLANTE_DEVICE_MAPPING = 'volante-device-mapping',
  V4_MENU = 'CDL-20309-V4-Menu-Composite',
}

export enum AP3FeatureFlags {
  USER_MANAGEMENT = 'AP3-USER-MANAGEMENT',
}
