<template>
  <Card v-bind="$attrs" class="cursor-pointer" @click="actionCallback">
    <template #header>
      <div class="flex">
        <Image :src="`${fullImageUrl}`" class="w-full h-13rem custom-image" v-if="src" />
        <Avatar :label="initial" class="w-full h-13rem custom-avatar" v-else />
      </div>
    </template>

    <template #content>
      <div class="flex flex-column content-height pb-4">
        <div class="flex mb-2">
          <h2 class="font-semibold">{{ title }}</h2>
        </div>
        <p v-if="subtitle" class="text-gray-500 m-0">{{ subtitle }}</p>
        <div class="flex flex-wrap">
          <slot name="tags" />
        </div>
      </div>
    </template>
  </Card>
</template>

<script setup lang="ts">
import { Image, Card, Avatar } from '../../../core';
import { computed, type ComputedRef } from 'vue';

const props = defineProps({
  title: {
    type: String,
    required: true,
  },
  subtitle: {
    type: String,
  },
  staticImage: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['action']);
const src = defineModel('srcImg', {
  type: String,
});

// Get title initials
const initial = computed(() => {
  return props.title?.match(/(\b\S)?/g).join('');
});

const fullImageUrl: ComputedRef<string> = computed(() => {
  if (props.staticImage) return src.value;
  return import.meta.env.VITE_AWS_BUCKET + src.value;
});

const actionCallback = () => {
  emit('action');
};
</script>

<script lang="ts">
export default { name: 'ImageListItem' };
</script>

<style scoped lang="scss">
.custom-image {
  object-fit: cover;
}

.content-height {
  height: 80px;
}

:deep(.custom-avatar) {
  .p-avatar-text {
    font-weight: 700;
    font-size: 3.5rem;
    color: #ffffff;
    text-transform: uppercase;
  }
}
</style>
