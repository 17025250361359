import { defineStore } from 'pinia';
import { SplitFactory } from '@splitsoftware/splitio';
import type { AP3FeatureFlags, FeatureFlags } from '@centric-os/types';
import { useAuthStore } from '@centric-os/stores';
interface State {
  featureFlags: Record<string, boolean>;
  initialized: Boolean;
  fetchingPromise: Promise<void>;
}

export const useSplitIoStore = defineStore('splitio', {
  state: (): State => ({
    featureFlags: {},
    initialized: false,
    fetchingPromise: null,
  }),
  getters: {
    isFeatureOn: (state) => {
      return (featureName: FeatureFlags | AP3FeatureFlags): boolean => {
        return state.featureFlags[featureName];
      };
    },
  },
  actions: {
    async fetchFeatureFlags(force = false) {
      if (this.initialized && !force) return;

      //in case simultaneous requests
      if (this.fetchingPromise) return this.fetchingPromise;

      this.fetchingPromise = this.doFetchFeatureFlags().then(() => {
        this.fetchingPromise = null;
      });
      return this.fetchingPromise;
    },
    async doFetchFeatureFlags() {
      const authStore = useAuthStore();
      const currentPath = window.location.pathname;

      // don't init before email exists as it might have additional rules
      if (
        !authStore?.cdlUser?.email &&
        currentPath !== '/password-reset' &&
        currentPath !== '/login'
      ) {
        return;
      }
      let config: SplitIO.IBrowserSettings;

      config = {
        core: {
          authorizationKey: import.meta.env.VITE_SPLIT_IO_API_KEY,
          key: authStore?.cdlUser?.email || 'key',
        },
      };

      const factory: SplitIO.IBrowserSDK = SplitFactory(config);

      const client = factory.client();
      const manager = factory.manager();

      try {
        await client.ready();
        await manager.ready();

        const split_names = manager.names();
        const treatments = client.getTreatments(split_names);

        const flags: any = {};
        Object.keys(treatments).forEach((key) => {
          flags[key] = treatments[key] === 'on';
        });
        this.featureFlags = flags;
        this.initialized = true;
        console.debug('Successfully fetched feature flags');
      } catch (err: any) {
        console.error('Failed fetching feature flags', err.message);
      } finally {
        client.destroy();
      }
    },
  },
});
